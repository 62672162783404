<template>
  <div>
    <strong>รายชื่อสมาชิกที่ถูกระงับ</strong>
    <div class="card">

      <div class="d-flex justify-content-between align-items-center">
        <div></div>
        <div style="width: 250px;" class="p-2">
          <input type="text" v-model="filter" class="form-control form-control-sm" placeholder="ค้นหา...">
        </div>
      </div>

      <b-table
        class="table-account"
        :fields="fields"
        :items="items"
        :show-empty="true"
        empty-text="ไม่มีข้อมูล"
        hover
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #cell(index)="data">
          {{ (perPage*(currentPage-1)) + data.index + 1 }}
        </template>
        <template #cell(username)="data">
          {{ data.item.user.username }}
        </template>
        <template #cell(role)="data">
          <AccountRole :role="data.item.role" />
        </template>
        <template #cell(fullname)="data">
          {{ data.item.user.firstName }} {{ data.item.user.lastName }}
        </template>
        <template #cell(blockdate)="data">
          <span>{{ dateFormat(data.item.blocked.blockedDate, 'DD/MM/YYYY') }}<br />{{ dateFormat(data.item.blocked.blockedDate, 'HH:mm:ss') }}</span>
        </template>
        <template #cell(blockby)="data">
          <span>{{ data.item.blocked.blockedBy.username }}</span>
        </template>
        <template #cell(blocknote)="data">
          <span>{{ data.item.blocked.blockedNote }}</span>
        </template>
        <template #cell(balance)="data">
          <span class="text-success font-weight-bold">{{ balanceFormat(data.item.balance) }}</span>
        </template>
        <template #cell(manage)="data">
          <button class="btn btn-warning btn-sm" @click="cancelBlock(data.item)">ยกเลิกระงับ</button>
        </template>
      </b-table>
    </div>

    <b-pagination
      v-model="currentPage"
      :total-rows="items.length"
      :per-page="perPage"
      align="right"
      size="md"
      class="my-0"
    ></b-pagination>

    <UnBlockAccountModal :data="blockItem" :is-show="isShowBlockModal" @close="modalClosed" />
  </div>
</template>
<script>
import ManageAccountService from '@/services/ManageAccountService'
import moment from '@/helpers/moment'
import {Roles} from '@/configs/account.config'

import UnBlockAccountModal from './components/UnBlockAccountModal'

export default {
  name: 'BlockAccount',
  components: {
    UnBlockAccountModal
  },
  data() {
    return {
      Accounts: [],
      fields: [
        {key: 'index', label: 'ลำดับ'},
        {key: 'username', label: 'ชื่อผู้ใช้'},
        {key: 'role', label: 'ระดับ'},
        {key: 'fullname', label: 'ชื่อ'},
        {key: 'blockdate', label: 'ระงับเมื่อ'},
        {key: 'blockby', label: 'ระงับโดย'},
        {key: 'blocknote', label: 'หมายเหตุ'},
        {key: 'balance', label: 'ยอดเงิน', tdClass: "text-right", thClass: "text-right"},
        {key: 'manage', label: '', tdClass: "text-right"},
      ],
      perPage: 20,
      currentPage: 1,
      filter: '',
      /**
       * ยกเลิกระงับการใช้งาน
       */
      isShowBlockModal: false,
      blockItem: {
        _id: null,
        username: null,
        blockNote: null
      }
    }
  },
  computed: {
    items() {
      return this.Accounts.map((a)=>{
        return {
          ...a,
          user: a.users[0]
        }
      })
    },
    agentLevels() {
      return this.$store.state.accountLevels
    },
  },
  methods: {
    loadBlockAccounts() {
      ManageAccountService.getBlockedAccounts()
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.Accounts = response.data
        }
      })
    },
    onFiltered() {
      this.currentPage = 1
    },
    accountLevel(_id) {
      const level = this.agentLevels.find((l)=>{
        return l._id === _id
      })
      return level?.levelName || ''
    },
    roleStyle(role) {
      const config = Roles.find((r)=>{
        return r.code === role
      })
      return config?.class || 'badge badge-secondary'
    },
    dateFormat(date, format='YYYY-MM-DD') {
      if(date) {
        return moment(date).format(format)
      }else{
        return '-'
      }
    },
    balanceFormat(balance) {
      return balance.toFixed(2)
    },
    cancelBlock(item) {
      this.blockItem = {
        _id: item._id,
        username: item.user.username,
        blockNote: null
      }
      this.isShowBlockModal = true
    },
    modalClosed(needReload) {
      this.isShowBlockModal = false
      if(needReload) {
        this.loadBlockAccounts()
      }
    },
  },
  mounted() {
    this.loadBlockAccounts()
  }
}
</script>
<style lang="scss">
.table-account {
  margin-bottom: 0;
  thead {
    tr {
      th {
        font-size: 90%;
        //font-weight: normal;
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        text-align: center;
        font-size: 90%;

        .badge {
          font-size: 90%;
          font-weight: normal;
        }
      }
    }
  }
  button.dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu.dropdown-menu-right {
    li {
      font-size: 90%;
    }
  }
}
</style>
<style lang="scss" scoped>
.breadcrumb {
  background-color: #fff;
  border-radius: 0;
  border-bottom: 0;
}
</style>
